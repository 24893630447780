import React from "react"
import PageLayout from "../components/page-layout"

import YukiMamiya from "../images/yukimamiya.jpg"
import MioOkamura from "../images/miookamura.jpg"
import YuaHishiro from "../images/yuahishiro.jpg"
import KaiMakise from "../images/kaimakise.jpg"
import AyariYumeka from "../images/ayariyumeka.jpg"
import AinaSatsuki from "../images/ainasatsuki.jpg"
import NamiNiinuma from "../images/naminiinuma.jpg"
import HananoTakeuchi from "../images/hananotakeuchi.jpg"
import MoekoIijima from "../images/moekoiijima.jpg"

export default function Home() {
    return (
        <PageLayout title="講師紹介">
            <div className="teacher">
                <div className="row main">
                    <div className="profile col-sm">
                        <div className="item">
                            <div className="profile-img-frame">
                                <img className="profile-img" src={YukiMamiya} alt="眞宮 由妃" />
                            </div>
                            <div className="profile-name">
                                眞宮 由妃
                                <div className="profile-name-roma">-&nbsp;Yuki Mamiya&nbsp;-</div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="profile-career">{`7歳よりバレエを始める。
                            高井由恵に師事。(仏)カンヌ・ロゼラ・ハイタワーバレエ学校にて研修。
                            15歳よりジャズダンスを始める。清水眞子に師事。
                            17歳で宝塚音楽学校に合格。
                            1997年第83期生として宝塚歌劇団に入団
                            娘役として月組に配属
                            2001年に宝塚歌劇団を退団
                            2005年よりバレエ講師活動を開始。都内バレエスタジオやダンススタジオ等にて、子どもクラス、大人クラス、宝塚受験生クラスを担当。
                            2021年YUKI MAMIYA BALLET STUDIOを開設。
                            日本バレエ協会正会員。`}<br />
                                ＊<a href="https://ameblo.jp/yuki-mamiya-ballet/" rel="noreferrer" target="_blank">YUKI MAMIYA BALLET STUDIO オフィシャルブログ</a><br />
                                ＊<a href="https://www.instagram.com/yukimamiya.ballet_studio/" rel="noreferrer" target="_blank">Instagram</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main row">
                    <div className="profile col-sm">
                        <div className="profile-img-frame">
                            <img className="profile-img" src={MioOkamura} alt="岡村未央" />
                        </div>
                        <div className="profile-name">岡村未央
                            <div className="profile-name-roma">-&nbsp;Mio Okamura&nbsp;-</div>
                        </div>
                        <div className="profile-career">{`７歳よりバレエを始める。
                            高井由恵に師事。
                            1986年にバレエ団・えぽっくに入団。谷口登美子に師事。付属研究所で講師活動を開始。以降、バレエ団・えぽっく公演、谷口バレエ研究所発表会、町田バレエ連盟、日本バレエ協会などの公演に出演する。スポーツクラブ、新体操クラブなどで指導をする。
                            1997年新国立劇場バレエ団に入団。
                            2006/2007シーズンまで所属。「眠れる森の美女」「白鳥の湖」「くるみ割り人形」をはじめ数々の公演に出演し研鑽を積む。バレエ団以外の公演にも出演する。
                            退団後に『MIO Ballet Class』を開講。
                            ほかバレエスタジオやバレエ同好会などで講師を務める傍ら、自身もフリーで舞台出演している。
                            日本バレエ協会正会員。`}</div>
                    </div>
                </div>
                <div className="main row">
                    <div className="profile col-sm">
                        <div className="profile-img-frame">
                            <img className="profile-img" src={NamiNiinuma} alt="新沼　奈美" />
                        </div>
                        <div className="profile-name">新沼 奈美
                            <div className="profile-name-roma">-&nbsp;Nami Niinuma&nbsp;-</div>
                        </div>
                        <div className="profile-career">{`3歳でモダンバレエ、
                            5歳でクラシックバレエを始める。
                            谷口登美子、余芳美に師事。
                            日本音楽高校バレエコース卒業後、モスクワバレエアカデミーへ留学。
                            帰国後、バレエ団えぽっく、スターダンサーズバレエ、N.Otani ballet company、などで踊り、バレエ教室やスポーツクラブにて指導を行う。`}
                        </div>
                    </div>
                </div>
                <div className="main row">
                    <div className="profile col-sm">
                        <div className="profile-img-frame">
                            <img className="profile-img" src={KaiMakise} alt="牧勢 海" />
                        </div>
                        <div className="profile-name">牧勢 海
                            <div className="profile-name-roma">-&nbsp;Kai Makise&nbsp;-</div>
                        </div>
                        <div className="profile-career">{`1997年第83期生として宝塚歌劇団に入団　
                            男役として雪組に配属
                            2003年に宝塚歌劇団を退団
                            その後、ニューヨークへダンス留学し、帰国後2006年より舞台復帰し振付の方でも活躍中
                            【主な舞台出演作品】
                            『DANCING CRAZY』『愛と青春の宝塚』『Side Show』『Victor victoria』『ブロードウェイライブ2010』ブロードウェイミュージカル宝塚OGバージョン『CHICAGO』など
                            【振付け作品】
                            『麗人コンサート』赤坂ACT
                            『越路吹雪に捧ぐ』日生劇場
                            『春野寿美礼ディナーショー』『香寿たつきディナーショー』宝塚ホテル
                            『黒蜥蜴』あうるすぽっと
                            『Life is Songs！〜』全国ツアー
                            宝塚OGコンサート
                            宝塚花月100周年「GreatestMoment」
                            NHK連続テレビ小説「カムカムエブリバディ」
                            など
                            【振付け助手】
                            ミュージカル『魔女の宅急便』
                            宝塚歌劇団　ANJU振付け助手。
                            【資格】
                            IHTAヨガインストラクター　
                            RYT200全米ヨガライセンス                            
                            (ジャズクラス担当)`}<br />
                            ＊<a href="https://ameblo.jp/makisekai/" rel="noreferrer" target="_blank">牧勢海オフィシャルブログ</a><br />
                            ＊<a href="https://www.instagram.com/kaimakise/" rel="noreferrer" target="_blank">Instagram</a>
                        </div>
                    </div>
                </div>
                <div className="main row">
                    <div className="profile col-sm">
                        <div className="profile-img-frame">
                            <img className="profile-img" src={AyariYumeka} alt="夢華 あやり" />
                        </div>
                        <div className="profile-name">夢華 あやり
                            <div className="profile-name-roma">-&nbsp;Ayari Yumeka&nbsp;-</div>
                        </div>
                        <div className="profile-career">{`5歳よりモダンバレエを始める。
                            志賀美也子に師事。その後クラシックバレエ、ジャズダンス、タップダンスを始める。
                            14歳でミュージカル『アニー』ジュリー役でデビュー。
                            18歳で宝塚音楽学校に合格。
                            2002年第88期生として宝塚歌劇団に入団
                            娘役として雪組に配属
                            2006年に宝塚歌劇団を退団。
                            退団後は山田裕美子として活躍。
                            主な出演作に『エリザベート』『スワンキング』『王家の紋章』『ブロードウェイと銃弾』『プロデューサーズ』『フランケンシュタイン』『レベッカ』『モーツァルト！』『ダンスオブヴァンパイア』『CHICAGO』ジューン役、地球ゴージャスプロデュース『海盗セブン』など。
                            (ジャズクラス担当)`}
                        </div>
                    </div>
                </div>
                <div className="main row">
                    <div className="profile col-sm">
                        <div className="profile-img-frame">
                            <img className="profile-img" src={AinaSatsuki} alt="沙月 愛奈" />
                        </div>
                        <div className="profile-name">沙月 愛奈
                            <div className="profile-name-roma">-&nbsp;Aina Satsuki&nbsp;-</div>
                        </div>
                        <div className="profile-career">{`4歳よりモダンバレエを始める。
                            1996年全国舞踊コンクールで1位を受賞。他にも数々のコンクールで上位を受賞。
                            17歳で宝塚音楽学校に合格。
                            2003年第89期生として宝塚歌劇団へ入団。娘役として雪組へ配属。
                            『ベルサイユのばら』『エリザベート』『ロミオとジュリエット』『ファントム』等に出演
                            2021年11月『CITY HUNTER』／『Fire Fever！』で退団。

                            博品館劇場『SAMBA NIGHT 2022』に出演。
                            今後は、舞台に関わらずテレビ、ラジオ、そして後進指導にも積極的に取り組んでいく。
                            (ジャズクラス担当)`}
                        </div>
                    </div>
                </div>
                <div className="main row">
                    <div className="profile col-sm">
                        <div className="profile-img-frame">
                            <img className="profile-img" src={YuaHishiro} alt="妃白 ゆあ" />
                        </div>
                        <div className="profile-name">妃白 ゆあ
                            <div className="profile-name-roma">-&nbsp;Yua Hishiro&nbsp;-</div>
                        </div>
                        <div className="profile-career">{`滋賀県出身　
                            幼少期よりクラッシックバレエを始める。
                            15歳で宝塚音楽学校に合格。
                            2006年第92期生として宝塚歌劇団に入団
                            娘役として星組に配属
                            「ロミオ＆ジュリエット」「オーシャンズ11」「ナポレオン」「おかしな二人」「風と共に去りぬ」等に出演
                            2016年「こうもり」「THE ENTERTAINER!」で退団。
                            退団後は、「キスミーケイト」「PIPPIN」「リビングルームミュージカル」「デスノート」「ローマの休日」等舞台を中心に活動する傍ら、ダンスクラスや宝塚受験生のレッスンも行う。
                            【資格】ジャイロトニック認定トレーナー`}<br />
                            ＊<a href="https://infinity-studio.jp/" rel="noreferrer" target="_blank">infinity studio</a><br />
                            ＊<a href="https://www.instagram.com/yua_hishiro/" rel="noreferrer" target="_blank">Instagram</a>
                        </div>
                    </div>
                </div>
                <div className="main row">
                    <div className="profile col-sm">
                        <div className="profile-img-frame">
                            <img className="profile-img" src={HananoTakeuchi} alt="妃白 ゆあ" />
                        </div>
                        <div className="profile-name">竹内 花野
                            <div className="profile-name-roma">-&nbsp;Hanano Takeuchi&nbsp;-</div>
                        </div>
                        <div className="profile-career">{`3歳よりジャズダンスを始める。その後バレエ、ヒップホップ、タップ、コンテンポラリーなど様々なジャンルを学ぶ。
                            清水眞子に師事。
                            高校卒業後、ニューヨークのBroadwayDanceCenterへ留学し帰国後はミュージカルやダンス公演に出演。

                            【主な舞台出演作品】
                            2018年　イタリア・ダンス公演
                            Marco vilgili 振付/演出『unconventional circus』
                            2018年『big the musical』
                            2021年、舞台『里見八犬伝』ソリストダンサー・山猫役。
                            (ジャズクラス担当)
                            `}
                        </div>
                    </div>
                </div>
                <div className="main row">
                    <div className="profile col-sm">
                        <div className="profile-img-frame">
                            <img className="profile-img" src={MoekoIijima} alt="妃白 ゆあ" />
                        </div>
                        <div className="profile-name">飯島 萌子
                            <div className="profile-name-roma">-&nbsp;Moeko Iijima&nbsp;-</div>
                        </div>
                        <div className="profile-career">{`幼少期よりバレエを習い、15歳でジャズダンスを始める。
                            清水眞子に師事。
                            洗足学園音楽大学ミュージカルコース卒業。
                            2017年、ニューヨークのSTEPS on Broadwayへ留学し、シアタージャズをはじめ、ホートンテクニックやコンテンポラリーなど幅広いジャンルを学び、2019年帰国。
                            2020年1月より清水ジャズダンススタジオで講師を務める。 
                            2020年4月、様々なジャンルのダンスを軸に、感情や物語を表現するパフォーマンスチーム劇DANsを結成し、劇場公演をはじめ幼稚園等でのパフォーマンスを中心に活動中。

                            【主な舞台出演作品】
                            2017年　ミュージカル座「ロスト・フォレスト」アンサンブル/振付助手
                            2018年　Broadway Cares/Equity Fights プレゼンツ “Theater of Dream” 
                            2018年〜2019年　Mindy dancin' Jackson プロジェクト “Fresh Meet” トリオダンサー
                            2022年　「TAP DO!劇場版20」
                            ほかダンス公演や映像作品に出演。
                            (ジャズクラス担当)
                            `}
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout >
    )
}